import SiteHeader from './header';
const {
  useSelector
} = ReactRedux;
export const HeaderContainer = ({
  hasMenu,
  onToggleMenu,
  messages,
  isErrorMessages,
  isFetchingMessages
}) => {
  const userRole = useSelector(({
    User
  }) => User?.role);
  const username = useSelector(({
    User
  }) => User?.name);
  return <SiteHeader hasError={Boolean(isErrorMessages)} hasMenu={hasMenu} isLoading={isFetchingMessages} messages={messages} onToggleMenu={onToggleMenu} username={username} userRole={userRole} />;
};