import { useStoredMessagesContext } from '../../stored-messages-provider';
import { getClassNamesFromArray } from '@common/utils';
import { ErrorPaper } from '@common/components/error-paper';
import Messages from './messages';
import { useUnreadMessageCount } from '../use-unread-message-count';
import './messages.less';
const {
  useState
} = React;
const {
  ReactButton: Button,
  ReactIcon: Icon
} = svs.ui;
const MessagesContainer = ({
  oldMessages,
  recentMessages,
  isLoading,
  hasError
}) => {
  const {
    readMessages,
    markMessageAsRead
  } = useStoredMessagesContext();
  const unreadOldMessageCount = useUnreadMessageCount(oldMessages, readMessages);
  const unreadRecentMessageCount = useUnreadMessageCount(recentMessages, readMessages);
  const [visibleRecentMessages, setVisibleRecentMessages] = useState(10);
  const [expanded, setExpanded] = useState(null);
  const unreadMessagesCount = unreadOldMessageCount + unreadRecentMessageCount;
  const handleAccordionChange = (messageId, isExpanded) => {
    setExpanded(isExpanded ? messageId : null);
    if (isExpanded) {
      markMessageAsRead(messageId);
    }
  };
  const loadMoreRecentMessages = () => {
    setVisibleRecentMessages(recentMessages.length);
  };
  return <div className={getClassNamesFromArray(['container-width', 'messages-error', isLoading && 'is-loading'])}>
      <div className="info-div u-flex">
        <Icon icon="information" size="300" />
        <span className="margin-left text-span">
          Detta är samma meddelanden som visas i Lottapplikationen och i Spelterminalen. Meddelandet markeras som läst 24 timmar efter att du klickat på det.
        </span>
      </div>
      {recentMessages.length !== 0 && <React.Fragment>
          <Messages expanded={expanded} messages={recentMessages.slice(0, visibleRecentMessages)} onAccordionChange={handleAccordionChange} readMessages={readMessages} title="Senaste 14 dagar" unreadMessagesCount={unreadMessagesCount} />
          {recentMessages.length > visibleRecentMessages && <div className="margin-top-2 u-flex u-flex-align-self-center u-flex-justify-content-center">
              <Button onClick={loadMoreRecentMessages} transparent>
                ladda fler
              </Button>
            </div>}
        </React.Fragment>}
      {oldMessages.length !== 0 && <div className="padding-top-3">
          <Messages expanded={expanded} messages={oldMessages} onAccordionChange={handleAccordionChange} readMessages={readMessages} title="Äldre meddelanden" unreadMessagesCount={unreadMessagesCount} />
        </div>}
      {hasError && <ErrorPaper />}
    </div>;
};
export default MessagesContainer;