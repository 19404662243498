import PrintMessageButton from './print-message-button';
import { isMessageRead } from '../is-message-read';
import './messages.less';
const {
  Accordion,
  AccordionDetails,
  AccordionItem,
  AccordionSummary
} = svs.ui.ReactAccordion;
const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const Messages = ({
  title,
  messages,
  expanded,
  onAccordionChange,
  readMessages
}) => {
  const now = new Date();
  return <React.Fragment>
      <div className="message-title hide-on-print">
        <Grid>
          <GridRow>
            <GridCol className="text padding-left-1" item>
              {title}
            </GridCol>
            <GridCol className="text-column">Datum</GridCol>
          </GridRow>
        </Grid>
      </div>
      <div className="messages">
        <Accordion customer="tur">
          {messages.map(message => {
          const savedDate = new Date(message.savedDate);
          const formattedDate = dateFns.format(savedDate, 'dd MMMM yyyy');
          const formattedTime = dateFns.format(savedDate, 'HH:mm:ss');
          const isExpanded = expanded === message.id;
          const isRead = isMessageRead(message, readMessages, now);
          return <AccordionItem isExpanded={isExpanded} key={message.id} onChange={(e, isExpanded) => onAccordionChange(message.id, isExpanded)}>
                <AccordionSummary className={`accordion-summary ${isRead ? 'read' : 'unread'}`}>
                  <Grid>
                    <GridRow>
                      <GridCol className="column-message-body" item>
                        <span className={isRead ? 'read-message' : 'unread-message'}>
                          {message.body}
                        </span>
                      </GridCol>
                      {!isExpanded && <GridCol className="column-message-date">
                          {dateFns.format(savedDate, 'yyyy-MM-dd HH:mm')}
                        </GridCol>}
                    </GridRow>
                  </Grid>
                </AccordionSummary>

                <AccordionDetails className="accordion-details">
                  <div>
                    <div className="accordion-details-body">
                      <span>
                        {formattedDate}
                      </span>
                      <span>
                        {formattedTime}
                      </span>
                    </div>
                    <p className="message-body-space">
                      {message.body}

                    </p>
                  </div>
                  <div>
                    <PrintMessageButton className="margin-bottom-1" message={message} />
                  </div>
                </AccordionDetails>
              </AccordionItem>;
        })}
        </Accordion>
      </div>
    </React.Fragment>;
};
export default Messages;