import UserMenu from './user-menu';
import { useStoredMessagesContext } from '../stored-messages-provider';
import { useUnreadMessageCount } from './use-unread-message-count';
import './styles.less';
const {
  useState
} = React;
const {
  ReactIcon: Icon,
  ReactIconButton: IconButton
} = svs.ui;
const {
  SiteHeaderBrand,
  SiteWrapper
} = svs.retailUi;
const {
  isAnonymous
} = svs.core.session.data.userSession;
const Badge = svs.ui.ReactBadge;
const SiteHeader = ({
  hasMenu,
  onToggleMenu,
  username,
  userRole,
  messages,
  isLoading,
  hasError
}) => {
  const {
    readMessages
  } = useStoredMessagesContext();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
  const recentMessages = messages.filter(message => new Date(message.savedDate) >= fourteenDaysAgo).sort((a, b) => new Date(b.savedDate) - new Date(a.savedDate));
  const oldMessages = messages.filter(message => new Date(message.savedDate) < fourteenDaysAgo).sort((a, b) => new Date(b.savedDate) - new Date(a.savedDate)).slice(0, 5);
  const unreadOldMessageCount = useUnreadMessageCount(oldMessages, readMessages);
  const unreadRecentMessageCount = useUnreadMessageCount(recentMessages, readMessages);
  const unreadMessagesCount = unreadOldMessageCount + unreadRecentMessageCount;
  return <header className="site-header">
      <div className="header header-default" id="top-content" role="banner">
        <div className="header-content">
          <SiteHeaderBrand />
          {hasMenu && <React.Fragment>
              <UserMenu hasError={hasError} isLoading={isLoading} isOpen={isUserMenuOpen} messages={messages} onClose={() => setIsUserMenuOpen(false)} username={username} userRole={userRole} />
              <button aria-controls="navigation-menu-main" aria-expanded="false" aria-label="Meny" className="nav-hamburger icon-button fc-black hamburger-icon" onClick={onToggleMenu} type="button">
                <Icon icon="menu" />
              </button>

            </React.Fragment>}
          {isDrawerOpen && <div className="site-menu visible-xs">
              <div className="close-icon">
                <IconButton onClick={() => setDrawerOpen(false)}>
                  <Icon icon="close" />
                </IconButton>

              </div>
              <div className="menu">
                <SiteWrapper />
              </div>
            </div>}

          {!isAnonymous && <button aria-controls="navigation-menu-main" aria-expanded="false" aria-label="Meny" className="nav-user icon-button fc-black" onClick={() => setIsUserMenuOpen(true)} type="button">
              <div className="header-icon-wrapper">
                <Icon icon="user" />
                {svs.core.detect.feature('ft-messages') && unreadMessagesCount > 0 && <Badge branding="red" size="small">
                    {unreadMessagesCount}
                  </Badge>}
              </div>
              <span className="nav-hamburger-title">
                {username}
              </span>
            </button>}
        </div>
      </div>
    </header>;
};
export default SiteHeader;