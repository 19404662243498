const {
  useRef
} = React;
const {
  ReactButton: Button
} = svs.ui;
const PrintMessageButton = ({
  message
}) => {
  const iframeRef = useRef(null);
  const handlePrintClick = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const messageDiv = document.createElement('div');
      ReactDOM.render(<div style={{
        padding: '54px 48px 0 48px'
      }}>

          <div style={{
          textAlign: 'right',
          paddingBottom: '30px'
        }}>
            Meddelande från Svenska Spel
          </div>
          <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '12px'
        }}>
            <span>
              {dateFns.format(new Date(message.savedDate), 'dd MMMM yyyy')}
            </span>
            <span>
              {dateFns.format(new Date(message.savedDate), 'HH:mm:ss')}
            </span>
          </div>
          <p>
            {message.body}
          </p>
        </div>, messageDiv);
      const messageHTML = messageDiv.innerHTML;
      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(`
        <html>
          <head>
            <style>
              @page {
                margin: 0;
              }
              body {
                margin: 20px;
                font-family: Arial, sans-serif;
              }
            </style>
          </head>
          <body>${messageHTML}</body>
        </html>
      `);
      doc.close();
      iframe.contentWindow.print();
    }
  };
  return <div style={{
    textAlign: 'right',
    paddingTop: '24px'
  }}>
      <Button onClick={handlePrintClick} size={300} transparent>
        Skriv ut
      </Button>
      <iframe ref={iframeRef} style={{
      display: 'none'
    }} title="Print iframe" />
    </div>;
};
export default PrintMessageButton;