const {
  createContext,
  useEffect,
  useContext,
  useState,
  useCallback,
  useMemo
} = React;
const StoredMessagesContext = createContext();
const VERSION = '2.0';
const hashStr = async str => {
  try {
    const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str));
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hashHex;
  } catch (error) {
    return null;
  }
};
const itemId = hash => `readMessages_${hash}`;
const storedValue = readMessages => JSON.stringify({
  version: VERSION,
  readMessages: Array.from(readMessages.entries()).map(([key, value]) => [key, {
    timestamp: value.timestamp
  }])
});
const createInitialState = hash => {
  try {
    const storedValue = localStorage.getItem(itemId(hash));
    if (storedValue) {
      const parsedValue = JSON.parse(storedValue);
      if (!parsedValue.version || parsedValue.version !== VERSION) {
        throw new Error('Version mismatch.');
      }
      return {
        readMessages: new Map(parsedValue.readMessages)
      };
    }
  } catch (error) {}
  return {
    readMessages: new Map()
  };
};
const InnerStoredMessagesProvider = ({
  children,
  hash
}) => {
  const [state, setState] = useState(() => createInitialState(hash));
  const markMessageAsRead = useCallback(messageId => {
    if (state.readMessages.has(messageId)) {
      return;
    }
    const newReadMessages = new Map(state.readMessages);
    newReadMessages.set(messageId, {
      timestamp: Date.now()
    });
    try {
      localStorage.setItem(itemId(hash), storedValue(newReadMessages));
    } catch (error) {}
    setState({
      readMessages: newReadMessages
    });
  }, [state.readMessages, hash, setState]);
  const value = useMemo(() => ({
    readMessages: state.readMessages,
    markMessageAsRead
  }), [state.readMessages, markMessageAsRead]);
  return <StoredMessagesContext.Provider value={value}>
      {children}
    </StoredMessagesContext.Provider>;
};
export const StoredMessagesProvider = ({
  children,
  retailerId,
  username
}) => {
  const [hash, setHash] = useState(null);
  useEffect(() => {
    const load = async () => {
      const h = await hashStr(`${retailerId}_${username}`);
      setHash(h);
    };
    load();
  }, [retailerId, username]);
  if (hash === null) {
    return null;
  }
  return <InnerStoredMessagesProvider hash={hash}>
      {children}
    </InnerStoredMessagesProvider>;
};
export const useStoredMessagesContext = () => useContext(StoredMessagesContext);